import React, { useEffect, useRef, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Socket, Channel } from 'phoenix';
import {
  Box,
  Typography,
  CircularProgress,
  Button, Grid,
} from '@mui/material';

interface WorkflowTimelineProps {
  workflowName: string;
  deviceId: string;
  baseUrlFlux: string;
  baseUrlApi: string
}

export interface WorkflowProgressHandle {
  joinChannelIfNeeded: () => void;
}

interface WorkflowData {
  bucket_percentage: number;
  completed_buckets: number;
  current_bucket: string;
  total_buckets: number;
  buckets: string[];
  current_step: {
    name: string;
    type: string;
  };
  user_input: {
    [key: string]: any;
  };
  progress_percentage: number;
}

const WorkflowProgress = forwardRef<WorkflowProgressHandle, WorkflowTimelineProps>(
  function WorkflowProgress(
    { workflowName, deviceId, baseUrlFlux, baseUrlApi },
    ref
  ) {
  const [workflowData, setWorkflowData] = useState<WorkflowData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  const socketRef = useRef<Socket | null>(null);
  const channelRef = useRef<Channel | null>(null);

  const activeRequestIdRef = useRef<string | null>(null);

  const fetchBuckets = useCallback(async () => {
    if (!workflowName || !deviceId) return;
    try {
      const currentRequestId = crypto.randomUUID();
      activeRequestIdRef.current = currentRequestId;

      setIsLoading(true);
      setError(null);

      const params = new URLSearchParams({ workflowName, deviceId });
      const res = await fetch(`${baseUrlApi}/api/v1/get_buckets?${params.toString()}`);
      if (!res.ok) {
        throw new Error(`Server responded with ${res.status}`);
      }

      const data = (await res.json()) as WorkflowData;
      let finalData;

      if ('progress' in data) {
        finalData = data.progress;
      } else if ('all_buckets' in data) {
        finalData = data;
      } else {
        throw new Error('Unrecognized response shape.');
      }


      if (activeRequestIdRef.current === currentRequestId) {
        setWorkflowData(finalData);
        setError(null);
      }
    } catch (err: any) {
      if (activeRequestIdRef.current) {
        setError(err.message ?? 'Something went wrong fetching workflow data.');
      }
    } finally {
      if (activeRequestIdRef.current) {
        setIsLoading(false);
      }
    }
  }, [workflowName, deviceId, baseUrlApi]);

  useEffect(() => {
    fetchBuckets();
  }, [fetchBuckets]);

  const joinChannelIfNeeded = useCallback(() => {
    if (channelRef.current) {
      console.log('Already joined channel; skipping join')
      return;
    }
    if (!workflowName || !deviceId) {
      console.log('Cannot join channel: missing workflowName or deviceId.');
      return;
    }

    const socketUrl = baseUrlFlux.startsWith('https')
        ? `wss://${baseUrlFlux.replace('https://', '')}/workflow_socket`
        : `ws://${baseUrlFlux.replace('http://', '')}/workflow_socket`;

    console.log('Attempting to join channel for deviceId:', deviceId);
    const newSocket = new Socket(socketUrl, { params: { device_id: deviceId } });
    newSocket.connect();
    socketRef.current = newSocket;

    const newChannel = newSocket.channel(`workflow:${deviceId}`, {});
    newChannel
      .join()
      .receive('ok', (resp) => {
        console.log('Joined channel successfully', resp);
        setWorkflowData((prev) => ({ ...prev, ...resp }));
        channelRef.current = newChannel;

        newChannel.on('workflow_update', (newData: WorkflowData) => {
          setWorkflowData(newData);
          
          // Force Google Translate to respect the updated step counter
          setTimeout(() => {
            updateStepCounterDisplay();
          }, 50);
        });
      })
      .receive('error', (resp) => {
        console.error('Unable to join channel', resp);
      });

  }, [workflowName, deviceId, baseUrlFlux]);

  // Function to directly update DOM for step counter if needed
  const updateStepCounterDisplay = useCallback(() => {
    if (!workflowData) return;
    
    const stepCounterElements = document.querySelectorAll('.step-counter-number');
    if (stepCounterElements.length > 0) {
      const fractionNumerator = workflowData.completed_buckets + 1;
      const fractionDenominator = workflowData.total_buckets;
      
      const numeratorEl = stepCounterElements[0];
      const denominatorEl = stepCounterElements[1];
      
      if (numeratorEl && numeratorEl.textContent !== String(fractionNumerator)) {
        numeratorEl.textContent = String(fractionNumerator);
      }
      
      if (denominatorEl && denominatorEl.textContent !== String(fractionDenominator)) {
        denominatorEl.textContent = String(fractionDenominator);
      }
    }
  }, [workflowData]);

  // expose joinChannelIfNeeded to the parent via ref:
  useImperativeHandle(ref, () => ({
    joinChannelIfNeeded,
  }));
  
  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <CircularProgress size={24} />
        <Typography variant="body2">Loading workflow…</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="body2">
        Error: {error}
      </Typography>
    );
  }

  if (!workflowData) {
    return null;
  }

  const { completed_buckets, total_buckets, user_input } = workflowData;
  const fractionNumerator = completed_buckets + 1;
  const fractionDenominator = total_buckets;
  const fractionColor =
    fractionNumerator > 1 && fractionNumerator < fractionDenominator
      ? 'green'
      : 'white';

  const driverPhone = user_input?.phone_number;

  return (
      <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
      >
        {/* Left: large step fraction */}
        <Grid item>
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
            <span style={{ fontSize: '2em' }} className="notranslate">
              Step <span className="step-counter-number">{fractionNumerator}</span>
            </span>
            <span style={{ fontSize: '1em' }} className="notranslate">
              {' / '} <span className="step-counter-number">{fractionDenominator}</span>
            </span>
          </Typography>
        </Grid>

        {/* Right: phone (centered vertically, right-justified) */}
        <Grid item>
          {driverPhone && (
              <Typography
                  variant="body2"
                  sx={{ color: 'white', fontSize: '1em', textAlign: 'right' }}
              >
                Phone: {driverPhone}
              </Typography>
          )}
        </Grid>
      </Grid>
  );
});

export default WorkflowProgress;